<template>
    <div class="wrapper">
        <div class="inner">
            <div class="row head">
                <div class="column"
                     :class="filters.sortingState[filters.group]"
                     @click="sortBy(sortList[$store.state.statistics.filters.group])"
                >
                    {{filters.group | uppercase}}
                </div>
                <div class="column">PLAYER LOADS</div>
                <div class="column">VIEWS</div>
                <div class="column" v-if="totalStatisticData.hasOwnProperty('impressions')">AD IMPRESSION</div>
                <div class="column" v-if="totalStatisticData.hasOwnProperty('fill_rate')">FILL RATE</div>
                <div class="column" v-if="totalStatisticData.hasOwnProperty('v_cpm')">vCPM</div>
                <div class="column" v-if="totalStatisticData.hasOwnProperty('net')">REVENUE</div>
            </div>
            <div class="body">
                <div class="row even total">
                    <div class="column">TOTAL</div>
                    <div class="column">{{totalStatisticData.loads || '-'}}</div>
                    <div class="column">{{totalStatisticData.views || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('impressions')">{{totalStatisticData.impressions || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('fill_rate')">{{totalStatisticData.fill_rate || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('v_cpm')">{{totalStatisticData.v_cpm || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('net')">{{totalStatisticData.net || '-'}}</div>
                </div>
                <div class="row"
                     :class="{even: index % 2 !== 0}"
                     v-for="(field, index) in statisticData"
                     :key="index"
                >
                    <div class="column">{{field.date || field.title || field.article}}</div>
                    <div class="column">{{field.loads || '-'}}</div>
                    <div class="column">{{field.views || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('impressions')">{{field.impressions || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('fill_rate')">{{field.fill_rate || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('v_cpm')">{{field.v_cpm || '-'}}</div>
                    <div class="column" v-if="totalStatisticData.hasOwnProperty('net')">{{field.net || '-'}}</div>
                </div>
            </div>
        </div>
        <BaseLoader v-if="loading" color="#e8703b"></BaseLoader>
    </div>
</template>

<script>
    export default {
        name: "StatisticsTableComponent",
        props: {
            statisticData: {
                default() {
                    return [];
                }
            },
            totalStatisticData: {
                default() {
                    return [];
                }
            },
            loading: {
                default() {
                    return false
                }
            }
        },
        methods: {
            sortBy(by){
                this.$emit('doSort', by)
            },
        },
        computed: {
            sortList(){
                return this.$store.state.static.statisticSortingAliases
            },
            filters(){
                return this.$store.state.statistics.filters
            }
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        border-top: solid 3px #e8e8e8;
        padding: 18px 30px 30px 25px;
        color: #424242;

        @media(max-width: 900px){
            padding: 10px 15px 10px 15px;
        }
        @media(max-width: 768px){
            overflow-x: auto;
        }
        .head {
            font-weight: bold;
            user-select: none;
            .column {
                cursor: pointer;
            }
        }
        .inner {
            min-width: 600px;
        }
    }
    .row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        padding: 20px 25px;
        font-weight: 500;
        font-size: 16px;
        @media(max-width: 768px){
            font-size: 14px;
        }
        .column {
            flex: 15;
            position: relative;
            text-align: center;
            &:first-child  {
                /*flex: 22;*/
                text-align: left;
                padding-right: 10px;
                /*@media(max-width: 768px){*/
                /*    flex: 28;*/
                /*}*/
            }
            &:last-child  {
                /*flex: 10;*/
            }

            &.desc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 73px;
                }
            }
            &.asc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: none;
                    border-bottom: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 73px;
                }
            }
        }
        &.even {
            background: #e8e8e8;
        }
    }
    .empty-table {
        padding: 30px;
        font-weight: 500;
        font-size: 20px;

    }
</style>
