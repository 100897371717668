<template>
    <div class="wrapper flex-column">
        <div class="video-search flex-between">
            <SearchVideoComponent @search="searchVideo" :placeholder="'Поиск по библиотеке видео'"></SearchVideoComponent>
        </div>
        <div class="filters-wrapper flex-between">
            <VideosFilterByAuthorsComponent @change="filterByAuthors"></VideosFilterByAuthorsComponent>
            <VideosFilterByCategoriesComponent @change="filterByCategory"></VideosFilterByCategoriesComponent>
        </div>
        <div class="filters-wrapper flex-between">
            <VideosFilterByLanguagesComponent @change="filterByLanguage"></VideosFilterByLanguagesComponent>
            <div class="sort-by-filter-wrapper flex-end">
                <span>Sort by: </span>
                <div class="buttons-wrapper">
                    <BaseButton class="btn-default"
                                :class="{'btn-filled': name === filters.sort_by}"
                                v-for="(name, index) in sortByButtons"
                                :key="index"
                                @click="name !== filters.sort_by && sortBy(name)"
                    >
                        {{name | capitalize}}
                    </BaseButton>
                </div>
            </div>
        </div>
        <div class="videos-list flex" v-scroll="handleScroll">
            <div class="inner">
                <div class="empty-list flex-center" v-if="!videosList.length && !loading">Видео отсутсвуют</div>
                <VideoItemComponent
                        :currentSite="currentSite"
                        v-for="video in videosList"
                        :key="video.id"
                        :videoInfo="video">
                </VideoItemComponent>
                <BaseLoader v-if="loading" :color="'#e8703b'"></BaseLoader>
            </div>
        </div>

    </div>
</template>

<script>
    import SearchVideoComponent from "../SearchComponent.vue";
    import VideoItemComponent from "./VideoItemComponent";
    import VideosFilterByCategoriesComponent from "./VideosFilterByCategoriesComponent";
    import VideosFilterByLanguagesComponent from "./VideosFilterByLanguagesComponent";
    import VideosFilterByAuthorsComponent from "./VideosFilterByAuthorsComponent";

    import _ from 'lodash';
    import {mapCacheActions} from 'vuex-cache';

    export default {
        name: "VideosComponent",
        props:['currentSite'],
        components: {
            SearchVideoComponent,
            VideoItemComponent,
            VideosFilterByCategoriesComponent,
            VideosFilterByLanguagesComponent,
            VideosFilterByAuthorsComponent,
        },
        data(){
            return {
                videosTotalCount : 10,
                loading : false,
                filters: {
                    limit: 9,
                    page: 0,
                    search: '',
                    sort_by: 'hot',
                    categories: [],
                    authors: [],
                    languages: [],
                },
                cacheTimeout: 1000 * 60 * 2,
                videosList: [],
                sortByButtons:['hot','new','top']
            }
        },
        methods: {
            ...mapCacheActions(['GET_VIDEOS_LIST']),
            searchVideo(data){
                this.filters.search = data;
                this.filters.page = 0;
            },
            createRequestQuery(){
                let query = `?&sort_by=${this.filters.sort_by}&limit=${this.filters.limit}&page=${this.filters.page}`;

                let createCategoriesQuery = () => {
                    return this.filters.categories.forEach((categoryId, index)=>{
                        query+=`&categories[${index}]=${categoryId}`
                    })
                };
                let createLanguagesQuery = () => {
                    return this.filters.languages.forEach((languageId, index)=>{
                        query+=`&languages[${index}]=${languageId}`
                    })
                };
                let createAuthorsQuery = () => {
                    return this.filters.authors.forEach((authorsId, index)=>{
                        query+=`&authors[${index}]=${authorsId}`
                    })
                };
                this.filters.search.length ? query+=`&search=${this.filters.search}` : null;
                this.filters.categories.length && createCategoriesQuery();
                this.filters.languages.length && createLanguagesQuery();
                this.filters.authors.length && createAuthorsQuery();

                return query;
            },
            getVideoSList(){
                this.loading = true;

                this.GET_VIDEOS_LIST({query:this.createRequestQuery(),
                  userType:this.$store.state.user.user_type === 2 ? 'publisher' : 'blogger'}, {
                    timeout: this.cacheTimeout
                })
                .then((res)=>{
                    if(this.filters.page === 0){
                        this.videosList = res.data;
                    } else {
                        this.videosList.push(...res.data)
                    }
                    this.loading = false;
                    this.videosTotalCount = res.count;
                })
            },
            handleScroll:  _.throttle(function(e, el) {
                if (this.videosList.length >= this.videosTotalCount || this.loading) return;

                let pos = el.getBoundingClientRect();
                if(pos.top + pos.height > window.innerHeight) return;
                this.filters.page++;
            }, 200, {
                leading:true
            }),
            sortBy(by){
                this.videosList.length <=3 ? this.videosList = [] : null; //Мини костыль! Если на екране всего один ряд контента, то очистим список, чтобы показался лоадер и не было скачка интерфейса
                Object.assign(this.filters, {
                    page: 0,
                    sort_by: by
                })
            },
            filterByCategory(categories){
                this.videosList.length <= 3 ? this.videosList = [] : null;
                Object.assign(this.filters, {
                    page: 0,
                    categories: categories
                })
            },
            filterByLanguage(languages){
                this.videosList.length <= 3 ? this.videosList = [] : null;
                Object.assign(this.filters, {
                    page: 0,
                    languages: languages
                })
            },
            filterByAuthors(authors){
                this.videosList.length <= 3 ? this.videosList = [] : null;
                Object.assign(this.filters, {
                    page: 0,
                    authors: authors
                })
            }
        },
        watch:{
            filters: {
                deep: true,
                handler() {
                    this.getVideoSList()
                }
            }
        },
        mounted() {
            this.getVideoSList();
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        background-color: #ffffff!important;
        width: 100%;
        flex: 1;
    }
    .video-search {
        padding: 0 30px 0 25px;
        height: 109px;
        align-items: center;
        border-bottom: solid 3px #e8e8e8;
        width: 100%;
        @media (max-width: 900px) {
            height: auto;
            padding: 5px 15px 5px 15px;
            .btn {
                height: 50px;
                width: auto;
            }
        }
        @media (max-width: 600px) {
            flex-wrap: wrap;
            .btn {
                margin-top: 7px;
                width: 100%;
                height: 46px;
            }
            a {
                width: 100%;
            }
        }
    }
    .videos-list {
        overflow: hidden;
        width: 100%;
        flex: 1;

        .inner {
            display: grid;
            grid-template-columns: repeat(3, 33.5%);
            margin-bottom: -3px;
            width: 100%;
            .empty-list {
                width: 100%;
                height: 100%;
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                grid-column-start: 1;
                grid-column-end: 4;
            }
            .loader {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }
    }
    .sort-by-filter-wrapper {
        width: 50%;
        border-left: solid 3px #e8e8e8;
        padding: 25px 30px 25px 25px;
        span {
            font-weight: bold;
            font-size: 22px;
            margin-right: 25px;
        }
        .buttons-wrapper {
            height: 50px;
            .btn {
                padding: 0 38px;
                border-right: none;
                border-radius: 0;
                height: 100%;
            }
            &:last-child {
                border-right: 1px solid #89a7c1!important;
            }
        }
    }
    .filters-wrapper {
        width: 100%;
        border-bottom: solid 3px #e8e8e8;
        padding: 0 30px 0 25px;
        .field{
            padding: 25px 0;
            height: 100%;
            width: 50%;
        }
    }
</style>
