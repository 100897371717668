<template>
    <BaseModal>
        <BaseLoader :color="'white'" :width="50" :height="50" v-if="loading"></BaseLoader>
        <div class="code" :class="{visible: !loading}">
            <img :src="`${staticDomain}/img/close-icon.png`" alt="" class="close-modal" @click="$emit('close')">
            <textarea v-model="codeText" class="code-text"></textarea>
            <div class="modal-footer">
                <BaseButton class="btn-primary copy" @click="copy">
                    Copy
                </BaseButton>
            </div>
        </div>
    </BaseModal>
</template>

<script>
    export default {
        name: "CodeComponent",
        props: ['codeText'],
        data(){
            return {
                loading: false,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            copy(){
                let copyText = document.querySelector('.code-text');
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
    .close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
        width: 20px;
        cursor: pointer;
    }
    video {
        background: black;
        width: 100%;
        max-height: 100vh;
        &:focus {
            outline: none;
        }
    }
    .code {
        opacity: 0;
        position: relative;
        &.visible{
            opacity: 1;
            transition: opacity 0.3s ease-in;
        }
    }
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        pointer-events: none;
     }
    .code-text {
        margin-top: 40px;
        width: 500px;
        height: 250px;
    }
    .modal-footer {
        width: 100%;
        height: 58px;
    }
    .btn.copy {
        float: right;
    }
</style>
