<template>
    <div class="wrapper ">
        <div class="content-container flex-column">
            <div class="flex-between subheader">
                <SelectSiteStatisticComponent
                        @changeSite="setNewSite">
                </SelectSiteStatisticComponent>
                <div>
                    <BaseLoader class="loader payoutloader" v-if="payoutProgress" :color="'#e8703b'" :width="'32px'" :height="'32px'"></BaseLoader>
                    <BaseButton class="btn-primary payout" :disabled="$store.state.user.hasPayout === 1 || payoutProgress" @click="payout">
                        Request payout
                    </BaseButton>
                    <BaseButton class="btn-primary logout" @click="logout">
                        Logout
                    </BaseButton>
                </div>
            </div>
            <header class="flex-between">
                <div class="money">Нераспределенный заработок: <span>{{balance}}</span></div>
                <div class="buttons flex">
                    <router-link v-for="(btn, index) in buttons"
                                 :key="index"
                                 :to="$store.state.static.routePaths.dashboardp + '/' + btn.route">
                        <BaseButton class="btn-default">
                            {{btn.title}}
                        </BaseButton>
                    </router-link>
                </div>

            </header>
            <router-view v-if="currentSite" class="dashboard-main" :currentSite="currentSite"></router-view>
            <BaseLoader v-else :color="'#e8703b'"></BaseLoader>
            <footer class="flex-center">
                <div class="headphones"></div>
                <p>По любым вопросам обращайтесь в службу поддержки <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a></p>
            </footer>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import SelectSiteStatisticComponent from "../components/p/dashboard/SelectSiteStatisticComponent";
    export default {
        name: "PubDashboardScreen",
        data(){
            return {
                balance: this.$store.state.user.balance || 0,
                payoutProgress: false,
                buttons:[{
                    title: 'Statistics', route: this.$store.state.static.routePaths.statistics
                },{
                    title: 'Videos', route: this.$store.state.static.routePaths.videos
                }]
            }
        },
        methods:{
            ...mapActions(['GET_WEBSITES_LIST', 'GET_USER_PUBLISHER_INFO', 'ADD_PAYOUT']),
            logout(){
                this.$store.dispatch('LOGOUT')
                    .then(()=>{
                        this.$router.push(this.$store.state.static.routePaths.login);
                    })
            },
            payout(){
                this.payoutProgress = true;
                this.ADD_PAYOUT()
                    .then(()=>{
                        this.$store.dispatch('GET_USER_PUBLISHER_INFO')
                            .then(() => {
                                this.payoutProgress = false;
                                console.info('Refreshed.');
                            })
                            .catch(() => {
                                this.payoutProgress = false;
                                console.info('Refresh fail.');
                            });
                    })
            },
            setNewSite(site){
                this.$store.commit('SET_SELECTED_SITE_ID', site.id)
            }
        },
        mounted(){
            this.GET_WEBSITES_LIST()
                .then(()=>{
                    this.setNewSite(this.$store.state.sitesList[0])
                })
        },
        computed:{
            currentSite(){
                return this.$store.state.currentWebsiteId
            }
        },
        components: {
            SelectSiteStatisticComponent
        }
    }
</script>

<style lang="scss" scoped>
    .loader {
        flex: 1;
    }
    .loader.payoutloader {
        position: fixed;
        z-index: 1;
        width: 32px;
        height: 32px;
        margin-top: -20px;
        margin-left: 74px;
    }
    .wrapper {
        background-color: #e8e8e8;
        display: flex;
        .content-container {
            /*background-color: #ffffff;*/
            height: 100%;
            max-width: 1140px;
            margin: 0 auto;
            width: 100%;
            min-height: 101vh;
        }
    }
    .container {
        max-width: 1140px;
        margin: 0 auto;
        height: 100%;
    }
    .subheader {
        width: 100%;
        padding: 10px 0 10px 25px;
        flex-wrap: wrap;
        /*background-color: #e8e8e8;*/

    }
    .payout {
        margin-right: 10px;
    }
    .btn-primary:disabled {
        background: #bbb;
        color: #777;
        border-color: #999;
        cursor: auto;
    }
    header {
        width: 100%;
        border-bottom: solid 3px #e8e8e8;
        padding: 0 30px 0 20px;
        flex-wrap: wrap;
        min-height: 110px;
        height: 110px;
        align-items: center;
        background-color: #ffffff;
        .buttons {
            .btn {
                border-right: none;
                border-radius: 0;
                height: 50px;
                width: 145px;
            }

            a:last-child {
                .btn {
                    border-right: 1px solid #89a7c1!important;
                }
            }
        }

        @media (max-width: 900px) {
            padding: 5px 15px 0;
            height: auto;

            img {
                order: 0;
                max-width: 180px;
            }
            .logout {
                height: 40px;
            }
            .buttons {
                order: 1;
                width: 100%;
                justify-content: center;
                margin: 5px 0;

            }
        }
        @media (max-width: 768px) {
            .btn {
                width: auto;
                height: 45px;
            }
            .logout {
                height: 40px;
            }
            .payout {
                height: 40px;
            }
        }
        @media (max-width: 530px) {
            img {
                width: 35%;
            }
            .btn {
                width: auto;
                height: 40px;
                padding: 0 15px;
            }
            .logout {
                height: 30px;
            }
            .payout {
                height: 30px;
            }
        }
        .router-link-active {
            .btn {
                border: 1px solid #89a7c1;
                color: #fff;
                background-color: #89a7c1;
            }
        }
        .logout {
            margin-left: 20px;
        }
        .payout {
            margin-left: 20px;
        }
        .money {
            vertical-align: middle;
            span {
                vertical-align: middle;
                font-weight: bold;
                color: #e8703b;
                font-size: 24px;
            }
        }
    }
    .dashboard-main {
        flex: 1;
        background-color: #ffffff;
    }
    footer {
        width: 100%;
        align-self: flex-end;
        height: 110px;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        border-top: solid 3px #e8e8e8;
        background-color: #ffffff;

        @media (max-width: 1400px) {
            margin-top: 20px;
        }
        @media (max-width: 900px) {
            padding: 0 15px;
        }
        img {
            width: 90px;
            @media (max-width: 768px) {
                display: none;
            }
        }
        a {
            color: #e8703b;
            text-decoration: underline;
        }
        .headphones {
            width: 90px;
            height: 90px;
            background-image: url('./../../public/img/headphones.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
</style>
