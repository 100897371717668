<template>
    <div class="wrapper">
        <div class="inner">
            <div class="row head">
                <div class="column">ID</div>
                <div class="column">TITLE</div>
                <div class="column">PLAYER LOADS</div>
                <div class="column">VIEWS</div>
                <div class="column">REVENUE</div>
                <div class="column">ACTIONS</div>
            </div>
            <div class="body" v-if="articlesData.length">
                <div class="row"
                     :class="{even: index % 2 !== 0}"
                     v-for="(field, index) in articlesData"
                     :key="index">
                    <div class="column">{{field.id}}</div>
                    <div class="column">{{field.title || '-'}}</div>
                    <div class="column">{{field.player_loads || '-'}}</div>
                    <div class="column">{{field.views || '-'}}</div>
                    <div class="column">{{field.revenue || '-'}}</div>
                    <div class="column actions">
                        <div class="play-video-icon action" title="Проиграть видео"  @click="playVideo(field)"></div>
                        <div class="go-to-statistic-icon action" title="Статистика статьи" @click="goToArticleStatistic(field)"></div>
                        <div class="edit-article-icon action" title="Редактировать статью" @click="editArticle(field.post_id)"></div>
                    </div>
                </div>
            </div>
            <div class="row even flex-center" v-else>
                <div class="column">-</div>
                <div class="column">-</div>
                <div class="column">-</div>
                <div class="column">-</div>
                <div class="column">-</div>
                <div class="column">-</div>
            </div>
        </div>
        <BaseLoader v-if="loading" color="#e8703b"></BaseLoader>
    </div>
</template>

<script>
    import PlayerComponent from "../PlayerComponent";
    export default {
        name: "ArticlesTableComponent",
        data(){
            return {

            }
        },
        props: {
            articlesData: {
                default() {
                    return [];
                }
            },
            loading: {
                default() {
                    return false
                }
            }
        },
        methods: {
            sortBy(by){
                this.$emit('doSort', by)
            },
            editArticle(post_id){
                location.href = `/wp-admin/post.php?post=${post_id}&action=edit`
            },
            goToArticleStatistic(field){
                this.$router.push(`${this.$store.getters.dashBoardRoute('statistics')}?article_id=${field.id}&title=${field.title}`)
            },
            playVideo(field){
                this.$modal.show(PlayerComponent, {
                    url: field.video_url
                }, {
                    height: 'auto',
                    width: window.innerWidth > 1300 ? '80%' : '100%',
                    adaptive: false,
                    reset: true,
                    resizable: false
                });
            },
        },
        computed: {
            filters(){
                return this.$store.state.statistics.filters
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        /*border-top: solid 3px #e8e8e8;*/
        padding: 18px 30px 30px 25px;
        color: #424242;

        @media(max-width: 900px){
            padding: 10px 15px 10px 15px;
        }
        @media(max-width: 768px){
            overflow-x: auto;
        }
        .head {
            font-weight: bold;
            /*user-select: none;*/
            /*.column {*/
            /*    cursor: pointer;*/
            /*}*/
        }
        .inner {
            min-width: 600px;
        }
    }
    .row {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 1fr 2fr 1fr;
        padding: 20px 25px;
        font-weight: 500;
        font-size: 16px;
        @media(max-width: 768px){
            font-size: 14px;
        }
        .column {
            flex: 15;
            position: relative;
            text-align: left;
            &:first-child  {
                flex: 6;
                padding-right: 10px;
            }
            &:last-child  {
                flex: 6;
            }

            &.desc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 73px;
                }
            }
            &.asc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: none;
                    border-bottom: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 73px;
                }
            }
        }
        &.even {
            background: #e8e8e8;
        }
    }
    .empty-table {
        padding: 30px;
        font-weight: 500;
        font-size: 20px;
    }
    .actions {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        .action {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .edit-article-icon {
            background-image: url('./../../../../public/img/icons8_settings_filled_24px.png');
        }
        .go-to-statistic-icon {
            background-image: url('./../../../../public/img/icons8_up_2_24px.png');
        }
        .play-video-icon {
            background-image: url("./../../../../public/img/icons8_circled_play_64px.png");
        }
    }
</style>
