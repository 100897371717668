<template>
    <div class="wrapper">
        <div class="inner">
            <div class="row head">
                <div class="column"
                     :class="filters.sortingState[filters.group]"
                     @click="sortBy(sortList[$store.state.statistics.filters.group])"
                >
                    {{filters.group | uppercase}}
                </div>
                <div class="column">VIEWS</div>
                <div class="column">COMPLETE VIEWS</div>
                <div class="column">CLICKS</div>
            </div>
            <div class="body" v-if="statisticData.length">
                <div class="row even total">
                    <div class="column">TOTAL</div>
                    <div class="column">{{totalStatistic.views}}</div>
                    <div class="column">{{totalStatistic.completed}}</div>
                    <div class="column">{{totalStatistic.clicks}}</div>
                </div>
                <div class="row"
                     :class="{even: index % 2 !== 0}"
                     v-for="(field, index) in statisticData"
                     :key="index">
                    <div class="column">{{field.date || field.title}}</div>
                    <div class="column">{{field.views || '0'}}</div>
                    <div class="column">{{field.complete_views || '0'}}</div>
                    <div class="column">{{field.clicks || '0'}}</div>
                </div>
            </div>
            <div class="row even flex-center" v-else>
                <div class="column">TOTAL</div>
                <div class="column">-</div>
                <div class="column">-</div>
                <div class="column">-</div>
            </div>
        </div>
        <BaseLoader v-if="loading" color="#e8703b"></BaseLoader>
    </div>
</template>

<script>
    export default {
        name: "StatisticsTableComponent",
        data(){
            return {
                totalStatistic: {
                    views: '-',
                    completed: '-',
                    clicks: '-',
                }
            }
        },
        props: {
            statisticData: {
                default() {
                    return [];
                }
            },
            loading: {
                default() {
                    return false
                }
            }
        },
        watch:{
            statisticData() {
                this.calculateTotalData();
            },
        },
        methods: {
            sortBy(by){
                this.$emit('doSort', by)
            },
            calculateTotalData(){
                let views = 0,
                    completed = 0,
                    clicks = 0;

                this.statisticData.forEach((val)=>{
                    views+= +val.views;
                    completed+= +val.complete_views;
                    clicks+= +val.clicks;
                });

                this.totalStatistic = {
                    views: views || 0,
                    completed: completed || 0,
                    clicks: clicks || 0
                }
            }
        },
        computed: {
            sortList(){
                return this.$store.state.static.statisticSortingAliases
            },
            filters(){
                return this.$store.state.statistics.filters
            }
        },
        mounted() {
            this.calculateTotalData();
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        border-top: solid 3px #e8e8e8;
        padding: 18px 30px 30px 25px;
        color: #424242;

        @media(max-width: 900px){
            padding: 10px 15px 10px 15px;
        }
        @media(max-width: 768px){
            overflow-x: auto;
        }
        .head {
            font-weight: bold;
            user-select: none;
            .column {
                cursor: pointer;
            }
        }
        .inner {
            min-width: 600px;
        }
    }
    .row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        padding: 20px 25px;
        font-weight: 500;
        font-size: 16px;
        @media(max-width: 768px){
            font-size: 14px;
        }
        .column {
            flex: 25;
            position: relative;
            @media(max-width: 768px){
                flex: 30;
            }
            &:first-child  {
                flex: 40;
                padding-right: 10px;
                /*@media(max-width: 768px){*/
                /*    flex: 28;*/
                /*}*/
            }
            &:last-child  {
                flex: 10;
                @media(max-width: 768px){
                    flex: 12;
                }
            }

            &.desc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 70px;
                }
            }
            &.asc{
                &:after {
                    content: '';
                    border: 7px solid transparent;
                    border-top: none;
                    border-bottom: 7px solid #8ba9c2;
                    position: absolute;
                    top: 8px;
                    left: 70px;
                }
            }
        }
        &.even {
            background: #e8e8e8;
        }
    }
    .empty-table {
        padding: 30px;
        font-weight: 500;
        font-size: 20px;

    }
</style>