<template>
    <div class="articles-wrapper">
        <div class="flex-end search-wrapper">
            <router-link :to="$store.getters.dashBoardRoute('videos')">
                <BaseButton class="btn-primary empty">Create article</BaseButton>
            </router-link>
            <SearchComponent :placeholder="'Search'" @search="doSearch"></SearchComponent>
        </div>
        <ArticlesTableComponent
                :articlesData="articlesList"
                :loading="loading"
                v-scroll="handleScroll"
        ></ArticlesTableComponent>
    </div>
</template>

<script>
    import {mapCacheActions} from 'vuex-cache';
    import _ from 'lodash';
    import ArticlesTableComponent from "./ArticlesTableComponent";
    import SearchComponent from "../SearchComponent";

    export default {
        name: "ArticlesComponent",
        props: ['currentSite'],
        components:{
            ArticlesTableComponent,
            SearchComponent,
        },
        data(){
            return {
                filters: this.$store.state.articles.filters,
                loading: false,
                totalCount: 0,
                cacheTimeout: 1000 * 60 * 2
            }
        },
        methods: {
            ...mapCacheActions(['GET_ARTICLES']),
            fetchData(){
                this.loading = true;

                this.GET_ARTICLES(this.createQueryStringToRequest(),{
                    timeout: this.cacheTimeout
                })
                .then((response)=>{
                    if(this.filters.page === 0){
                        this.$store.commit('SET_ARTICLES_LIST', response);
                    } else {
                        this.$store.commit('APPEND_ARTICLES_LIST', response);
                    }

                    this.loading = false;
                    this.totalCount = response.count;
                })
            },
            doInitRequest(){
                this.$store.commit('SET_ARTICLES_FILTERS', {
                    website_id: this.$store.state.currentWebsiteId,
                });
            },
            createQueryStringToRequest(){
                let queryString = `website_id=${this.filters.website_id}&limit=${this.filters.limit}&page=${this.filters.page}`;
                this.filters.title ? queryString+=`&title=${this.filters.title}` : null;
                return queryString
            },
            handleScroll:  _.throttle(function(e, el) {
                if (this.articlesList.length >= this.totalCount || this.loading) return;

                let pos = el.getBoundingClientRect();
                if(pos.top + pos.height > window.innerHeight) return;
                this.filters.page++;
            }, 200, {
                leading:true
            }),
            doSearch(val){
                if (val.length < 3) {
                    this.filters.title = '';
                    return
                }
                this.filters.title = val;
            },
        },
        watch: {
            filters: {
                handler(){
                    this.fetchData();
                },
                deep: true
            },
            currentSite(id){
                this.$store.commit('SET_ARTICLES_FILTERS', {
                    website_id: id,
                });
            },
        },
        created(){
            this.doInitRequest()
        },
        computed: {
            articlesList(){
                return this.$store.state.articles.articlesData;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .articles-wrapper {
        width: 100%;
    }
    .search-wrapper {
        background: #ffffff;
        padding: 20px 30px 10px;
        .btn {
            height: 50px;
            width: 145px;
        }
        ::v-deep .video-filter-wrapper {
            justify-content: flex-end;
            flex: 0;
            .video-filter {
                width: 280px;
                height: 50px;
                margin-left: 10px;
                input {
                    padding-left: 10px;
                    font-weight: 500;
                    &::placeholder {
                        font-weight: 500;
                    }
                }
            }
        }

    }
</style>
