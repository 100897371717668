<template>
    <div class="wrapp flex-center" @click="isOpen =!isOpen">
        <div class="site-name flex-center" @click="showList=!showList">
            {{currentSite.title | uppercase}}
            <div class="triangle" :class="{open : isOpen}"></div>
        </div>
        <div class="site-list-selector" v-if="showList">
            <div class="site-item"
                 v-for="(site, index) in sitesList"
                 :key="index"
                 @click="changeSite(site)">
                {{site.title}}
            </div>
            <BaseButton class="btn-primary empty" @click="addSite">Добавить новый...</BaseButton>
        </div>
    </div>

</template>

<script>
    import AddSiteComponent from "../AddSiteComponent";

    export default {
        name: "SelectSiteStatisticComponent",
        data(){
            return {
                isOpen: false,
                currentSite: {},
                showList: false,
            }
        },
        computed:{
            sitesList(){
                return this.$store.state.sitesList
            }
        },
        watch: {
            sitesList(list) {
                this.changeSite(list[0]);
            }
        },
        methods: {
            changeSite(site){
                this.showList = false;
                this.currentSite = site;
                this.$emit('changeSite', site)
            },
            addSite() {
                this.$modal.show(AddSiteComponent, {}, {
                    height: 'auto',
                    width: window.innerWidth > 1300 ? '80%' : '100%',
                    adaptive: false,
                    reset: true,
                    resizable: false
                }, {
                    // 'before-close': (event) => { console.log('this will be called before the modal closes'); }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapp {
        position: relative;
        user-select: none;
    }
    .site-name {
        color: #424242;
        font-weight: bold;
        font-size: 24px;
        cursor: pointer;
        padding-right: 20px;
    }
    .site-list-selector {
        background-color: #fff;
        position: absolute;
        bottom: 5px;
        transform: translateY(100%);
        border: solid 1px #424242;
        left: 0;
        min-width: 100%;
        user-select: none;

        .site-item {
            cursor: pointer;
            padding: 10px;
            white-space: nowrap;
            font-weight: 500;


            &:hover {
                background-color: #e8e8e8;
            }
        }
    }
    .triangle {
        position: relative;

        &.open {
            &:after {
                bottom: -3px;
                border-top: none;
                border-bottom: 7px solid #424242;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: -25px;
            bottom: -12px;
            border: 7px solid transparent;
            border-top: 7px solid #424242;
        }
    }
</style>
