<template>
    <div class="wrapper flex-between">
        <span>Date interval:</span>
        <date-picker ref="datepicker" class="date-interval-indicator"
                 range
                 v-model="dateInterval"
                :shortcuts="shortcuts"
                :lang="lang"
                :format="format"
                :confirm="false"
                :clearable="false"
                :editable="false"
                :range-separator="'-'"
                :popupStyle="calendarStyle"
        >
            <template v-slot:header >
                <div class="calendar-header">
                    <span  @click="setDateInterval('-31')">За месяц | </span>
                    <span  @click="setDateInterval('-6')">За неделю | </span>
                    <span  @click="setDateInterval(0)">Сегодня</span>
                    <span class="close" @click="closeCalendar">X</span>
                </div>
            </template>
            <template v-slot:footer >
                <div class="calendar-footer">
                    <span @click="closeCalendar">Закрыть</span>
                </div>
            </template>
        </date-picker>
    </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker' // Документация плагина https://github.com/mengxiong10/vue2-datepicker
    export default {
        name: "DateFilterComponent",
        components: { DatePicker },
        data() {
            return {
                dateInterval: '',
                lang: {
                    days: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    months: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                    pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
                    placeholder: {
                        date: 'Выберите дату',
                        // dateRange: `${this.getDateString(new Date().adjustDate(-6))} - ${this.getDateString(new Date())}`
                    }
                },
                format: 'YYYY.MM.DD',
                calendarIsVisible: false,
                calendarStyle: {
                    top: '50px',
                    right:0,
                    left: 'inherit'
                },
                shortcuts: [{
                        text: 'За месяц',
                        onClick: () => {
                            this.dateInterval = [ new Date().adjustDate(-31), new Date() ]
                        }
                    },{
                        text: 'За неделю',
                        onClick: () => {
                            this.dateInterval = [ new Date().adjustDate(-6), new Date() ]
                        }
                    },{
                        text: 'Сегодня',
                        onClick: () => {
                            this.dateInterval = [ new Date(), new Date() ]
                        }
                    },
                ],
            }
        },
        watch:{
            dateInterval(val){
                this.$emit('changeDateInterval', val);
            }
        },
        methods:{
            closeCalendar(){
                this.$refs.datepicker.closePopup();
            },
            setDateInterval(beginDate){
                this.dateInterval = [ new Date().adjustDate(beginDate), new Date() ];
                this.closeCalendar();
            },
            setInitialDateInterval(){
                if(this.filters.start_date){
                    this.$refs.datepicker.selectStartDate(this.filters.start_date);
                    this.$refs.datepicker.selectEndDate(this.filters.end_date);
                }
            },
            getDateString(date){
                return date.toISOString().slice(0, 10)
            }
        },
        computed:{
            filters(){
                return this.$store.state.statistics.filters
            }
        },
        mounted() {
           this.setInitialDateInterval();
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100%;
        border-bottom: solid 3px #e8e8e8;
        border-right: solid 3px #e8e8e8;
        padding: 0 25px;
        align-items: center;
        @media(max-width: 1100px){
            padding: 0 10px 0 25px;
        }
        @media(max-width: 900px){
            padding: 0 15px;
        }
        @media(max-width: 768px){
            border-right: none;
        }
        @media(max-width: 500px){
            flex-wrap: wrap;
        }
        span {
            color: #424242;
            font-weight: bold;
            white-space: nowrap;
            margin-right: 10px;
            @media(max-width: 500px){
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
        ::v-deep .date-interval-indicator {
            width: 100%;
            max-width: 380px;
            @media(max-width: 900px){
                max-width: 75%;
            }
            @media(max-width: 500px){
                max-width: 100%;
            }
            input {
                width: 100%;
                height: 50px;
                border: 1px solid #afc7da;
                color: #89a7c1;
                display: flex;
                align-items: center;
                padding-left: 20px;
                font-weight: 500;
                font-size: 16px;
                border-radius: 0;
                cursor: pointer;
                box-shadow: none;
                background: #ffffff;
                &::placeholder{
                    color: #89a7c1;
                }
            }
            .mx-shortcuts {
                margin: 10px 0;
                font-weight: 500;
            }
            .mx-input-append{
                display: none;
            }

            @media(max-width: 530px){
                .mx-range-wrapper {
                    width: auto;
                }
                .mx-datepicker-popup {
                    max-width: 250px;
                }
                .mx-shortcuts {
                    font-size: 13px;
                }
            }
            .calendar-header {
                text-align: left;
                padding: 4px 12px;
                line-height: 34px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                display: flex;
                align-items: center;
                span {
                    color: #89a7c1;
                    font-weight: 500;
                    font-size: 15px;
                    cursor: pointer;
                    margin: 0 5px 0 0;
                    @media(max-width: 530px){
                        font-size: 13px;
                    }
                    &:hover {
                        color: #afc7da;
                    }
                }
                .close {
                    text-align: right;
                    justify-content: flex-end;
                    width: 100%;
                    margin: 0;
                }
            }
            .calendar-footer {
                text-align: right;
                padding: 10px;
                cursor: pointer;
                span {
                    color: #89a7c1;
                    font-weight: 500;
                    font-size: 15px;
                    &:hover {
                        color: #afc7da;
                    }
                }
            }
        }
    }
</style>
