<template>
    <div class="video-filter-wrapper flex-between">
        <div class="video-filter flex">
            <label class="search-video">
                <input v-bind="$attrs"
                       type="text"
                       v-model.trim="searchQuery"
                >
            </label>
            <div class="search-icon"></div>
        </div>
    </div>

</template>

<script>
    import _ from 'lodash';

    export default {
        name: "SearchComponent",
        inheritAttrs: false,
        data(){
            return {
                searchQuery: '',
            }
        },
        methods: {
            doSearch: _.debounce(function (value) { // Запрос отправляется не чаще чем раз в пол секунды
                if (value.length > 1) {
                    this.$emit('search', this.searchQuery)
                } else {
                    this.$emit('search', '')
                }
            }, 500),
        },
        watch:{
            searchQuery(val){
                this.doSearch(val);
            }
        },
        computed:{
            searchImg(){
                return require('../../../public/img/search.png');
            }
        },
    }
</script>

<style src="../../assets/css/search-video-component.scss" lang="scss" scoped ></style>
<style lang="scss" scoped>
    .video-filter-wrapper {
        flex: 1;
        padding: 0;
    }
    .search-video {
        width: 100%!important;
        padding-right: 35px;
        @media (max-width: 600px) {
            padding-right: 26px;
        }
    }
    .video-filter, input {
        background-color: #e8e8e8;
        height: 56px;
        @media (max-width: 900px) {
            height: 50px;
        }
    }
    .search-icon {
        width: 15px;
        height: 15px;
        background-image: url('./../../../public/img/search.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .video-filter {
        padding: 0 10px!important;
        @media (max-width: 600px) {
            img {
                top: 16px!important;
                right: 10px!important;
            }
        }
    }
</style>
