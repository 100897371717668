import { render, staticRenderFns } from "../../../assets/templates/search-component.html?vue&type=template&id=3a087e3c&scoped=true&"
import script from "./StatisticVideoFilterComponent.vue?vue&type=script&lang=js&"
export * from "./StatisticVideoFilterComponent.vue?vue&type=script&lang=js&"
import style0 from "../../../assets/css/search-video-component.scss?vue&type=style&index=0&id=3a087e3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a087e3c",
  null
  
)

export default component.exports