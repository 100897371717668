<template>
    <div class="video-item flex-column">
        <div class="wrapper">
            <div class="overlay flex-center" @click="showPlayerModal">
                <div class="play-icon"></div>
            </div>
            <div class="poster">
                <BaseImage :src="videoInfo.poster_url"></BaseImage>
            </div>
        </div>
        <div class="flex-between bottom-info" :title="videoInfo.title" >
            <span class="title">{{videoInfo.title}}</span>
            <div title="Скопировать код" class='new-article' @click="copyCode()">
                <BaseLoader v-if="processingCode"
                            :color="'#e8703b'"
                            :width="20"
                            :height="20"
                ></BaseLoader>
                <img v-else :src="`${staticDomain}/img/code_icon_64_b.png`" alt="">
            </div>
            <div title="Создать новую статью" class='new-article' @click="createNewArticle()">
                <BaseLoader v-if="processing"
                            :color="'#e8703b'"
                            :width="20"
                            :height="20"
                ></BaseLoader>
                <img v-else :src="`${staticDomain}/img/icons8_add_file_30px.png`" alt="">
            </div>
        </div>

    </div>
</template>

<script>
    import PlayerComponent from "../PlayerComponent";
    import CodeComponent from "../CodeComponent";
    import { mapActions } from 'vuex';

    export default {
        name: "VideoItemComponent",
        props: ['videoInfo', 'currentSite'],
        data(){
            return{
                codeOptions: {
                    vast: 'no vast',
                },
                playerOptions: {
                    url: this.videoInfo.url,
                },
                processing: false,
                processingCode: false,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            ...mapActions(['GET_VAST_DATA', 'GET_CODE', 'GET_WP_CODE']),
            showPlayerModal(){
                this.$modal.show(PlayerComponent, this.playerOptions, {
                    height: 'auto',
                    width: window.innerWidth > 1300 ? '80%' : '100%',
                    adaptive: false,
                    reset: true,
                    resizable: false
                }, {
                    // 'before-close': (event) => { console.log('this will be called before the modal closes'); }
                });
            },
            createNewArticle(){
                this.processing = true;
                this.GET_VAST_DATA({website_id: this.$store.state.currentWebsiteId})
                    .then(({vast}) => {
                        const codeParams = {
                            video_id: this.videoInfo.id,
                            video_url: this.videoInfo.url,
                            poster: this.videoInfo.poster_url,
                            website_id: this.$store.state.currentWebsiteId,
                            click_url: this.videoInfo.click_url ? this.videoInfo.click_url : '',
                            vast,
                            video_title: this.videoInfo.title
                        };
                        this.GET_WP_CODE(codeParams)
                            .then(({code}) => {
                                //let {id, url, title, poster_url, click_url} = this.videoInfo;
                                this.codeOptions.codeText = code;

                                this.$modal.show(CodeComponent, this.codeOptions, {
                                    height: 'auto',
                                    width: window.innerWidth > 1300 ? '80%' : '100%',
                                    adaptive: false,
                                    reset: true,
                                    resizable: false
                                }, {
                                    'before-close': () => { this.processing = false; }
                                });
                            });
                    });
            },
            copyCode(){
                this.processingCode = true;
                this.GET_VAST_DATA({website_id: this.$store.state.currentWebsiteId})
                    .then(({vast}) => {
                        const codeParams = {
                            video_id: this.videoInfo.id,
                            video_url: this.videoInfo.url,
                            poster: this.videoInfo.poster_url,
                            website_id: this.$store.state.currentWebsiteId,
                            click_url: this.videoInfo.click_url ? this.videoInfo.click_url : '',
                            vast,
                            video_title: this.videoInfo.title
                        };
                        this.GET_CODE(codeParams)
                            .then(({code}) => {
                                //let {id, url, title, poster_url, click_url} = this.videoInfo;
                                this.codeOptions.codeText = code;

                                this.$modal.show(CodeComponent, this.codeOptions, {
                                    height: 'auto',
                                    width: window.innerWidth > 1300 ? '80%' : '100%',
                                    adaptive: false,
                                    reset: true,
                                    resizable: false
                                }, {
                                    'before-close': () => { this.processingCode = false; }
                                });
                            });
                    });
            },
        },
        computed: {
            paths(){
                return this.$store.state.static.routePaths
            }
        }
    }
</script>

<style lang="scss" scoped>
    .video-item {
        /*width: 33.33333%;*/
        height: 300px;
        border-bottom: solid 3px #e8e8e8;
        border-right: solid 3px #e8e8e8;
        padding: 20px;

        .bottom-info {
            width: 100%;
            align-items: center;
            padding: 12px 0;
            .new-article {

            }
            .new-article {
                cursor: pointer;
                align-items: center;
                display: flex;
                .loader{
                    min-height: auto;
                }
                img {
                    width: 20px;
                }
            }
            .title {
                color: #424242;
                font-size: 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 500;
                width: 80%;
            }
        }

        .wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            user-select: none;
            &:hover {
                .overlay{
                    background-color: rgba(0,0,0, 0);
                }
            }

            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0, 0);
                user-select: none;
                .play-icon {
                    width: 46px;
                    height: 46px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url('./../../../../public/img/icons8_circled_play_64px.png');
                }
                @media (hover), (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
                    .play-icon {
                      background-image: url('./../../../../public/img/icons8_circled_play_64px.png');
                    }
                    &:hover {
                        background-color: rgba(0,0,0, 0.5);
                        .play-icon {
                          background-image: url('./../../../../public/img/icons8_circled_play_64px.png');
                        }
                    }
                }
            }
            .poster {
                height: 100%;
                width: 100%;
                background-color: #e8e8e8;
                max-height: 210px;
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }

</style>
