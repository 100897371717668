
export default {
    data(){
        return {
            searchQuery: '',
            loading: true,
            focused: false,
        }
    },
    watch:{
        searchQuery(value){
            if (value.length < 3) return;
            this.loading = true;
            this.doSearch(value);
        }
    },
    methods: {
        addToFilter(video){
            if (!video) return; //Защита от преждевременного нажатия на enter
            this.resultList.push(video);
            this.$emit('change', this.resultList);
            this.clearHints()
        },
        removeFromFilter(video){
            this.resultList.splice(this.resultList.findIndex((item)=>{
                return item.id === video.id
            }), 1);

            this.$emit('change', this.resultList);
        },
        clearAllFilters(){
            this.resultList.splice(0, this.resultList.length);
            this.$emit('change', this.resultList);
        },
    }
}
