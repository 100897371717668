<template>
    <BaseModal>
        <BaseLoader :color="'white'" :width="50" :height="50" v-if="loading"></BaseLoader>
        <div class="player" :class="{visible: !loading}">
            <img :src="`${staticDomain}/img/close-icon.png`" alt="" class="close-modal" @click="$emit('close')">
            <video :src="url" controls  ref="video" @canplay="onCanplay"></video>
        </div>
    </BaseModal>
</template>

<script>
    export default {
        name: "PlayerComponent",
        props: ['url'],
        data(){
            return {
                loading: true,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        beforeDestroy() {
            this.$refs.video.pause();
        },
        methods:{
            onCanplay(){
                this.loading = false;
                this.$refs.video.play();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
    .close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
        width: 20px;
        cursor: pointer;
    }
    video {
        background: black;
        width: 100%;
        max-height: 100vh;
        &:focus {
            outline: none;
        }
    }
    .player {
        opacity: 0;
        position: relative;
        &.visible{
            opacity: 1;
            transition: opacity 0.3s ease-in;
        }
    }
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        pointer-events: none;
     }
</style>
