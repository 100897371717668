<template>
    <BaseModal>
        <img :src="`${staticDomain}/img/close-icon.png`" alt="" class="close-modal" @click="$emit('close')">
        <form class="add-site-form"
              @submit.prevent=""
              novalidate>
            <AuthFormErrorComponent :error="error"></AuthFormErrorComponent>
            <header class="flex-center flex-between">
                <h2 class="title">Add site</h2>
            </header>
            <p class="input-title">Site domain:</p>
            <BaseInput v-model.trim="domain"
                       maxlength="maxlength"
                       :error="error">
            </BaseInput>
            <div class="flex-end">
                <div class="flex-end submit-buttons">
                    <BaseButton class="cancel-btn btn-primary empty" @click="$emit('close')">Cancel</BaseButton>
                    <BaseButton class="btn-primary add-site-btn" @click="submit" :processing="loading">Add</BaseButton>
                </div>
            </div>
        </form>
    </BaseModal>
</template>

<script>
    import {mapActions} from "vuex";
    import AuthFormErrorComponent from "../authorisation/AuthFormErrorComponent";

    export default {
        name: "AddSiteComponent",
        data(){
            return {
                domain: '',
                error: '',
                loading: false,
                maxlength: 191,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods:{
            ...mapActions(['ADD_NEW_SITE']),
            submit() {
                this.error = '';
                this.validateForm(()=>{
                    this.loading = true;
                    this.ADD_NEW_SITE(this.createFormDataToRequest())
                        .then(()=>{
                            setTimeout(()=>{
                                this.$router.go(0);
                            }, 500);
                        })
                        .catch((err)=>{
                            this.loading = false;
                            this.error = err.message;
                        });
                });
            },
            validateForm(callback){
                if (!this.domain.length || this.domain.length > this.maxlength ||
                    !this.domain.match(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/)) {
                    this.error = 'Wrong domain name';
                    return
                }
                callback();
            },
            createFormDataToRequest() {
                let form = new FormData();
                form.append('domain', this.domain);
                return form;
            }
        },
        components: {
            AuthFormErrorComponent,
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
    }
    .close-modal {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
        width: 20px;
        cursor: pointer;
    }
    .submit-buttons {
        .btn {
            @media (max-width: 600px) {
                width: 50%;
            }
        }
        .cancel-btn {
            margin-left: 0;
        }
    }
    .add-site-form {
        background-color: white;
        padding: 20px;
    }
    .add-site-btn {
        margin: 10px;
    }
</style>
