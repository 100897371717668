<template>
    <div class="wrapper flex-between">
        <span>Group by:</span>
        <div class="filter-type-indicator">
            <BaseDropDownBtn
                    :selected="selected"
                    @change="emitChangingUp"
                    style="padding: 0 20px"
                    :list="dropdownList">
            </BaseDropDownBtn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FilterTypeSelectorComponent",
        data(){
            return {
                dropdownList: [{
                    selectorTitle: 'Date',
                    type: this.$store.state.static.statisticFiltersGroupsAliases.date,
                },{
                    selectorTitle: 'Video',
                    type: this.$store.state.static.statisticFiltersGroupsAliases.video,
                },]
            }
        },
        methods: {
            emitChangingUp(val){
                this.$emit('setFilterType', val.type)
            }
        },
        computed: {
            selected(){
                return this.dropdownList.find((item)=>{
                    return item.type === this.$store.state.statistics.filters.group
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 100%;
        border-bottom: solid 3px #e8e8e8;
        padding: 0 30px;
        align-items: center;

        @media(max-width: 1100px){
            padding: 0 25px 0 10px;
        }
        @media(max-width: 900px){
            padding: 0 15px;
        }
        @media(max-width: 500px){
            flex-wrap: wrap;
        }
        span {
            color: #424242;
            font-weight: bold;
            margin-right: 10px;
            white-space: nowrap;
            @media(max-width: 500px){
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
        .filter-type-indicator {
            width: 100%;
            max-width: 380px;
            height: 50px;
            border: 1px solid #afc7da;
            color: #424242;
            font-weight: 500;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0;
            @media(max-width: 900px){
                max-width: 75%;
            }
            @media(max-width: 500px){
                max-width: 100%;
            }
        }
    }
    ::v-deep .list {
        width: 100%;
        background: #fff;
        top: 50px;
        left: 0;
        border: 1px solid #afc7da;

        .list-item {
            padding: 7px 20px;
            &.active {
                background-color: #e8e8e8;
            }
        }
    }
</style>
