<template>
    <div class="wrapper">
        <div class="video-search flex-between">
            <SearchVideoComponent @search="searchVideo"></SearchVideoComponent>
            <router-link :to="$store.state.static.routePaths.addVideo">
                <BaseButton class="btn-primary empty add-video-btn">ADD video</BaseButton>
            </router-link>
        </div>
        <div class="videos-list" v-scroll="handleScroll">
            <div class="inner">
                <VideoItemComponent v-for="(video, index) in videosList"
                                    :key="index"
                                    :videoInfo="video">
                </VideoItemComponent>
            </div>
        </div>
        <BaseLoader v-if="loading" :color="'#e8703b'"></BaseLoader>
    </div>
</template>

<script>
    import SearchVideoComponent from "./SearchVideoComponent.vue";
    import VideoItemComponent from "./VideoItemComponent";
    import _ from 'lodash';
    import {mapCacheActions} from 'vuex-cache';

    export default {
        name: "VideosComponent",
        components: {
            SearchVideoComponent,
            VideoItemComponent,
        },
        data(){
            return {
                videosTotalCount : 10,
                loading : false,
                filters: {
                    limit: 9,
                    page: 0,
                    search: '',
                },
                cacheTimeout: 1000 * 60 * 2,
                videosList: []
            }
        },
        methods: {
            ...mapCacheActions(['GET_VIDEOS_LIST']),
            searchVideo(data){
                this.filters.search = data;
                this.filters.page = 0;
            },
            resetCache(){
                this.$store.cache.delete('GET_VIDEOS_LIST',this.createRequestQuery(), {
                    timeout: this.cacheTimeout
                });
            },
            createRequestQuery(){
                let query = `?limit=${this.filters.limit}&page=${this.filters.page}`;
                this.filters.search.length ? query+=`&search=${this.filters.search}` : null;
                return query;
            },
            getVideoSList(){
                this.loading = true;
                this.GET_VIDEOS_LIST({query:this.createRequestQuery(),
                    userType:this.$store.state.user.user_type === 2 ? 'publisher' : 'blogger'}, {
                    timeout: this.cacheTimeout
                })
                .then((res)=>{
                    if(this.filters.page === 0){
                        this.videosList = res.data;
                    } else {
                        this.videosList.push(...res.data)
                    }
                    this.loading = false;
                    this.videosTotalCount = res.count;
                })
            },
            handleScroll:  _.throttle(function(e, el) {
                if (this.videosList.length >= this.videosTotalCount || this.loading) return;

                let pos = el.getBoundingClientRect();
                if(pos.top + pos.height > window.innerHeight) return;
                this.filters.page++;
            }, 200, {
                leading:true
            }),
        },
        watch:{
            filters: {
                deep: true,
                handler() {
                    this.getVideoSList()
                }
            }
        },
        created(){
            this.$route.params.initSearchQuery ? this.filters.search = this.$route.params.initSearchQuery : null;
        },
        mounted() {
            if (this.$route.params.resetCache) this.resetCache();
            this.getVideoSList();
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        background-color: #ffffff!important;
        width: 100%;
        flex: 1;
    }
    .video-search {
        padding: 0 30px 0 20px;
        height: 109px;
        align-items: center;
        border-bottom: solid 3px #e8e8e8;
        width: 100%;
        @media (max-width: 900px) {
            height: auto;
            padding: 5px 15px 5px 15px;
            .btn {
                height: 50px;
                width: auto;
            }
        }
        @media (max-width: 600px) {
            flex-wrap: wrap;
            .btn {
                margin-top: 7px;
                width: 100%;
                height: 46px;
            }
            a {
                width: 100%;
            }
        }
    }
    .videos-list {
        overflow: hidden;
        margin-bottom: -3px;
        width: 100%;
        flex: 1;
        .inner {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            width: calc(100% + 3px);
        }
    }
</style>
