<template>
    <div class="statistics-wrapper">
        <div class="flex-between">
            <DateFilterComponent class="static-filter" @changeDateInterval="setDateInterval"></DateFilterComponent>
            <FilterTypeSelectorComponent class="static-filter" @setFilterType="changeFilterGroup"></FilterTypeSelectorComponent>
        </div>
        <div class="filters-wrapper">
            <StatisticsCommonFiltersComponent @changeFilters="changeFilters"></StatisticsCommonFiltersComponent>
        </div>
        <StatisticsTableComponent
                :statisticData="statisticData"
                :totalStatisticData="totalStatisticData"
                :loading="loading"
                @doSort="doSort"
                v-scroll="handleScroll"
        ></StatisticsTableComponent>
    </div>
</template>

<script>
    //ВАЖНО: таблица начинает отстраиваться по вотчеру currentSite. Как только мы получим список сайтов и получим id активного - шлем запрос на статистику для конкретного сайта
    import DateFilterComponent from "../statistics/DateFilterComponent";
    import FilterTypeSelectorComponent from "../statistics/FilterTypeSelectorComponent";
    import StatisticsTableComponent from "../statistics/StatisticsTableComponent";
    import StatisticsCommonFiltersComponent from "../statistics/StatisticsCommonFiltersComponent";
    import {mapCacheActions } from 'vuex-cache';
    import _ from 'lodash';

    export default {
        name: "StatisticsComponent",
        components: {
            DateFilterComponent,
            FilterTypeSelectorComponent,
            StatisticsTableComponent,
            StatisticsCommonFiltersComponent,
        },
        props:['currentSite'],
        data(){
            return {
                // filters:{
                //     start_date: null,
                //     end_date: null,
                //     videos: [],
                //     group: this.$store.state.statistics.currentStatisticGroup,
                // },
                filters: this.$store.state.statistics.filters,
                sortBy: this.$store.state.statistics.sortBy,
                videoFiltersList: this.$store.state.statistics.videoFiltersList,
                loading: false,
                currentPage: 0,
                totalCount: 0,
                cacheTimeout: 1000 * 60 * 2
            }
        },
        methods: {
            ...mapCacheActions (['GET_STATISTIC','GET_NEXT_STATISTIC_PAGE']),
            createQueryStringToRequest(){
                let {videos, website_id, group, limit, articles, start_date, end_date, sortingState} = this.filters,
                    queryString = `?website_id=${website_id}&group=${group}&sort_field=${group}&limit=${limit}&page=${this.currentPage}`;

                videos.forEach((video)=>{
                    queryString+=`&videos[]=${video.id}`
                });
                articles.forEach((article)=>{
                    queryString+=`&articles[]=${article.id}`
                });
                start_date ? queryString+=`&start_date=${this.getDateIntervalString(start_date)}` : null;
                end_date ? queryString+=`&end_date=${this.getDateIntervalString(end_date)}` : null;
                queryString+=`&sort_direction=${sortingState[group]}`;

                return queryString
            },
            changeFilterGroup(type){
                this.filters.group = type;
            },
            changeFilters(val, type){
                this.filters[type] = val;
            },
            handleChangingFilters(){
                this.currentPage = 0; // При смене фильтра обязательно обнуляем номер страницы для запроса
                this.fetchStatisticData();
            },
            fetchStatisticData(){
                this.GET_STATISTIC({
                    query: this.createQueryStringToRequest(),
                    userType: this.$store.state.user.user_type,
                },{
                    timeout: this.cacheTimeout
                })
                    .then((response)=>{
                        this.$store.commit('SET_TOTAL_STATISTIC', response);
                        this.$store.commit('SET_STATISTIC', response);
                        this.loading = false;
                        this.totalCount = response.total.count;
                    })
            },
            loadAndAppendStatisticData(){
                this.GET_NEXT_STATISTIC_PAGE({
                    query: this.createQueryStringToRequest(),
                },{
                    timeout: this.cacheTimeout
                })
                    .then((response)=>{
                        this.$store.commit('APPEND_STATISTIC', response);
                        this.loading = false;
                    })
                    .catch(()=>{
                        this.loading = false;
                    })
            },
            setDateInterval(interval){
                Object.assign(this.filters, {
                    start_date: interval[0],
                    end_date: interval[1],
                })
            },
            getDateIntervalString(interval){ // Поправка Date.toISOString на часовой пояс
                let timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
                return (new Date(interval - timezoneOffset)).toISOString().slice(0, 10); //YYYY-MM-DD
            },
            doSort(by){
                this.filters.sortingState[by] = this.filters.sortingState[by] ==='asc' ? 'desc' : 'asc';
            },
            handleScroll:  _.throttle(function(e, el) {
                if (this.statisticData.length >= this.totalCount || this.loading) return; //Если все страницы загружены или в данный момент идет загрузка следующей страницы, то игнорируем обработку скрола

                let pos = el.getBoundingClientRect();
                if(pos.top + pos.height > window.innerHeight) return;
                this.loading = true;
                this.currentPage++;
                this.loadAndAppendStatisticData();
            }, 200, {
                leading:true
            }),
            doInitRequest(){
                let initFilters = {
                    website_id: this.$store.state.currentWebsiteId
                };

                if (this.$route.query.article_id !== undefined) {
                    this.$store.commit('SET_STATISTIC_ARTICLES_FILTERS_LIST', [{
                        id: this.$route.query.article_id,
                        title: this.$route.query.title || '',
                    }]);
                    initFilters.articles = [{id: this.$route.query.article_id}]
                }
                this.$store.commit('SET_STATISTIC_FILTERS', initFilters);
                this.fetchStatisticData();
            },
        },
        watch: {
            filters: {
                handler(){
                    this.handleChangingFilters();
                },
                deep: true
            },
            currentSite(id){
                this.$store.commit('SET_STATISTIC_FILTERS', {
                    website_id: id,
                });
            }
        },
        created(){
            this.doInitRequest()
            // this.handleInitRouteParams();
            // this.handleChangingFilters();
        },
        computed:{
            statisticData(){
                return this.$store.state.statistics.statisticData
            },
            totalStatisticData(){
                return this.$store.state.statistics.totalStatisticData
            },
        },
    }
</script>

<style lang="scss" scoped>
    .statistics-wrapper {
        flex: 1;
        width: 100%;
    }
    .filters-wrapper {
        padding: 18px 28px;
    }
    .static-filter {
        width: 50%;
        padding-top: 28px;
        padding-bottom: 28px;
        @media(max-width: 900px){
            padding-top: 10px;
            padding-bottom: 10px;
        }
        @media(max-width: 768px){
            width: 100%;
        }
    }

</style>
