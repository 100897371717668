<template>
    <div class="video-item flex-column">
        <div class="wrapper">
            <div class="overlay flex-center" @click="showLoginModal">
                <div class="play-icon" v-if="!showLoader"></div>
                <div v-else>Видео в процессе обработки...</div>
                <BaseLoader v-if="showLoader" :height="20" :width="20" :color="'#e8703b'" ></BaseLoader>
            </div>
            <div class="poster">
                <img :src="videoInfo.poster_url" alt="" @error="onImgError" @load="onImgLoad">
            </div>
        </div>
        <div class="flex-between bottom-info" :title="videoInfo.title">
            <span class="title">{{videoInfo.title}}</span>
            <router-link :to="getLinkToVideoFilter()">
                <img :src="`${staticDomain}/img/icons8_add_file_30px.png`" alt="">
            </router-link>
        </div>

    </div>
</template>

<script>
    import PlayerComponent from "../../PlayerComponent";
    import {mapCacheActions} from "vuex-cache";
    export default {
        name: "VideoItemComponent",
        props: ['videoInfo'],
        data(){
            return{
                playerOptions: {
                    url: this.videoInfo.url
                },
                imgLoaded: false,
                showLoader: true,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            ...mapCacheActions(['GET_VIDEO_STATUS']),
            showLoginModal(){
                this.$modal.show(PlayerComponent, this.playerOptions, {
                    height: 'auto',
                    width: window.innerWidth > 1300 ? '80%' : '100%',
                    adaptive: false,
                    reset: true,
                    resizable: false
                }, {
                    // 'before-close': (event) => { console.log('this will be called before the modal closes'); }
                });
            },
            getLinkToVideoFilter(){
                return `${this.paths.dashboard}/${this.paths.statistics}?video_id=${this.videoInfo.id}&title=${this.videoInfo.title}`
            },
            checkStatus(){
                if (this.videoInfo.status === 1) {
                    this.GET_VIDEO_STATUS({id: this.videoInfo.id, c: Math.floor(Math.random() * 1e8)})
                        .then((res) => {
                            this.videoInfo = res.data
                            this.videoInfo.poster_url = this.videoInfo.poster_url + '?c=1';
                        });
                } else if (this.imgLoaded) {
                    this.$cron.stop('checkStatus');
                } else {
                    const rnd = Math.round(Math.random() * 1e8);
                    this.videoInfo.poster_url = this.videoInfo.poster_url.replace(/\?c=\d+/, `?c=${rnd}`);
                }
            },
            onImgError(){
                this.imgLoaded = false;
                if (!this.videoInfo.poster_url.match(/\?c=\d+/)) {
                    this.videoInfo.poster_url = this.videoInfo.poster_url + '?c=1';
                }
                this.$cron.start('checkStatus');
            },
            onImgLoad(){
                this.imgLoaded = true;
                this.showLoader = false;
            },
        },
        computed: {
            paths(){
                return this.$store.state.static.routePaths
            }
        },
        mounted() {
            if (this.videoInfo.status === 1) {
                this.showLoader = true;
                this.$cron.start('checkStatus');
            }
        },
        cron:{
            time: 5000,
            method: 'checkStatus',
            autoStart: false
        },

    }
</script>

<style lang="scss" scoped>
    .video-item {
        width: 33.33333%;
        height: 300px;
        border-bottom: solid 3px #e8e8e8;
        border-right: solid 3px #e8e8e8;
        padding: 20px;
        @media (max-width: 900px) {
            padding: 10px 15px;
            width: 50%;
        }
        @media (max-width: 600px) {
            width: 100%;
        }
        .bottom-info {
            width: 100%;
            align-items: center;
            padding: 12px 0;
            a {
                align-items: center;
                display: flex;
                img {
                    width: 20px;
                }
            }
            .title {
                color: #424242;
                font-size: 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 500;
                width: 80%;
            }
        }

        .wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            &:hover {
                .overlay{
                    background-color: rgba(0,0,0, 0);
                }
            }
            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0,0,0, 0);
                user-select: none;
                .play-icon {
                    width: 46px;
                    height: 46px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url( './../../../../public/img/icons8_circled_play_gray_64px.png');
                }
                @media (hover) , (min-width:0\0) , (min--moz-device-pixel-ratio:0) {
                    .play-icon {
                        background-image: url( './../../../../public/img/icons8_circled_play_gray_64px.png');
                    }
                    &:hover {
                        background-color: rgba(0,0,0, 0.5);
                        .play-icon {
                            background-image: url( './../../../../public/img/icons8_circled_play_gray_64px.png');
                        }
                    }
                }
            }
            .poster {
                height: 100%;
                width: 100%;
                background-color: #e8e8e8;
                max-height: 210px;
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }

    }
</style>
