<template>
    <div class="wrapper">
        <div class="content-container flex-column">
            <header class="flex-between">
                <img :src="`${staticDomain}/img/logo.png`" alt="Distribune">
                <div class="buttons flex">
                    <router-link v-for="(btn, index) in buttons"
                                 :key="index"
                                 :to="$store.state.static.routePaths.dashboard + '/' + btn.route">
                        <BaseButton class="btn-default">
                            {{btn.title}}
                        </BaseButton>
                    </router-link>
                    <BaseButton class="btn-primary logout" @click="logout" :processing="processing">
                        Logout
                    </BaseButton>
                </div>

            </header>
            <router-view class="dashboard-main"></router-view>

            <footer class="flex-center">
                <img :src="`${staticDomain}/img/headphones.png`" alt="">
                <p>По любым вопросам обращайтесь в службу поддержки <a :href="`mailto:${$store.state.static.contact_email}`">{{$store.state.static.contact_email}}</a></p>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DashboardScreen",
        data(){
            return {
                buttons:[{
                    title: 'Statistics', route: this.$store.state.static.routePaths.statistics
                },{
                    title: 'Videos', route: this.$store.state.static.routePaths.videos
                }],
                processing: false,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods:{
            logout(){
                this.processing = true;
                this.$store.dispatch('LOGOUT')
                    .then(()=>{
                        this.$router.push(this.$store.state.static.routePaths.login);
                        this.processing = false;
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        background-color: #e8e8e8;
        .content-container {
            background-color: #ffffff;
            height: 100%;
            max-width: 1140px;
            margin: 0 auto;
            width: 100%;
            min-height: 101vh;
        }
    }
    header {
        width: 100%;
        border-bottom: solid 3px #e8e8e8;
        padding: 0 30px;
        flex-wrap: wrap;
        min-height: 110px;
        height: 110px;
        align-items: center;
        .buttons {
            .btn {
                border-radius: 0;
            }

        }
        @media (max-width: 900px) {
            padding: 10px 15px;
            height: auto;
            min-height: auto;
            .btn {
                width: auto;
                height: 45px;
            }
            img {
                max-width: 180px;
            }

            .buttons {
                justify-content: center;
                margin: 5px 0;
            }
        }

        @media (max-width: 630px) {
            img {
                width: 35%;
            }
            .btn {
                width: auto;
                height: 40px;
                padding: 0 15px;
            }
        }
        @media (max-width: 630px) {
            justify-content: flex-end;
            img {
                display: none;
            }
        }
        .router-link-active {
            .btn {
                border: 1px solid #89a7c1;
                color: #fff;
                background-color: #89a7c1;
            }
        }
        .logout {
            margin-left: 20px;
        }
    }
    .dashboard-main {
        /*min-height: 700px;*/
    }
    footer {
        width: 100%;
        align-self: flex-end;
        height: 110px;
        padding: 0 30px;
        font-size: 16px;
        font-weight: 500;
        border-top: solid 3px #e8e8e8;
        text-align: center;
        /*@media (max-width: 1400px) {*/
        /*    margin-top: 20px;*/
        /*}*/
        @media (max-width: 900px) {
            padding: 0 15px;
        }
        img {
            width: 90px;
            @media (max-width: 768px) {
                display: none;
            }
        }
        a {
            color: #e8703b;
            text-decoration: underline;
        }
    }

</style>
