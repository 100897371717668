<template>
    <div class="field languages flex">
        <div class="flex selector">
            <span class="title">Языки:</span>
            <BaseMultipleDropdownBtn
                             v-if="list.length"
                             :list="list"
                             :emptyItem="clearAllLanguagesItem"
                             @change="onChange">
            </BaseMultipleDropdownBtn>
        </div>
    </div>
</template>

<script>
    import {mapCacheActions} from 'vuex-cache';
    export default {
        name: "VideosFilterByLanguagesComponent",
        data(){
            return {
                list: [],
                clearAllLanguagesItem: {
                    selectorTitle: 'Не выбрано',
                    language: {
                        id: ''
                    },
                },
            }
        },
        methods: {
            ...mapCacheActions(['GET_LANGUAGES_FOR_NEW_VIDEO']),
            onChange(val){
                this.$emit('change', this.getIdsArr(val));
            },

            getIdsArr(arr){
                return arr.map((item)=>{
                    return parseInt(item.language.id)
                })
            },
        },
        mounted() {
            this.GET_LANGUAGES_FOR_NEW_VIDEO()
                .then((response)=>{
                    this.list.push(...response.map((val)=>{
                        return {
                            selectorTitle: val.title,
                            language: val
                        }
                    }))
                })
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        min-height: 40px;
        flex-wrap: wrap;

        span {
            min-width: 100px;
            font-weight: bold;
            i {
                color: red;
            }
        }

        .selector {
            width: 100%;
            align-items: center;

            height: 52px;
            span {
                margin-right: 25px;
            }
        }
    }
    ::v-deep .dropdown {
        height: 52px;
        border: 1px solid #afc7da;
        border-radius: 2px;
        padding: 0 20px;
        flex: 1;
        max-width: 380px;

        span {
            font-weight: 500;
            opacity: 1;
        }
        &.selected {
            span {
                opacity: 1;
            }
        }
        .list-wrapper{
            top: 53px;
            left: 0;
        }
        .list {
            width: 100%;
            background: #fff;
            border: 1px solid #afc7da;
            overflow-y: scroll;
            height: 200px;

            .list-item {
                padding: 7px 20px;

                &.active {
                    background-color: #e8e8e8;
                }
            }
        }
        .ok-btn {
            width: 100%;
            text-align: center;
            padding: 5px 0;
            background: #ffffff;
            border: 1px solid #afc7da;
            border-top: none;
        }
    }
</style>
