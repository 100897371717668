<template>
    <transition name="fade">
        <div class="error-text flex-start" v-show="error">
            <span
                 v-html="error">
            </span>
            <a v-if="additionalParams"
               :href="additionalParams.path"
               @click.prevent="goToErrorLink"
            >{{additionalParams.message}}</a>
        </div>

    </transition>
</template>

<script>
    export default {
        name: "AuthFormErrorComponent",
        props: ['error','additionalParams'],
        data(){
            return {
                inside: false
            }
        },
        methods: {
            goToErrorLink(){
                this.$router.push({
                    name: this.additionalParams.pathName,
                    params: this.additionalParams.params
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .error-text {
        z-index: 10;
        min-height: 50px;
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        padding: 10px;
        border: solid 1px #f05145;
        border-radius: 3px;
        background: #f05145;
        text-align: center;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .1s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
        transition: opacity 0s;
    }
    a {
        color: #000;
        text-decoration: underline;
        padding-left: 6px;
    }
</style>
