<template>
    <div>
        <BaseButton class="btn-primary empty add-filter-btn" @click="addFilter">Add filters</BaseButton>
        <div class="filter flex"
             v-for="(field, index) in activeFilters"
             :key="index"
        >
            <BaseDropDownBtn :list="availableFilters"
                             :selected="field"
                             :unactiveItemsList="getUnactiveItems(field)"
                             @change="(e) => changeFilterType(e, index)"
            >
            </BaseDropDownBtn>
            <component :is="field.component"
                       @change="(val) => $emit('changeFilters', val, field.type)"
                       @clearFilters="removeFilter(index)"
            ></component>
        </div>

    </div>
</template>

<script>
    import StatisticVideoFilterComponent from "./StatisticVideoFilterComponent";
    import StatisticArticleFilterComponent from "./StatisticArticleFilterComponent";
    import _ from 'lodash';
    export default {
        name: "StatisticsCommonFiltersComponent",
        components: {
            StatisticVideoFilterComponent,
        },
        data(){
            return {
                activeFilters: [],
                availableFilters: [{
                    selectorTitle: 'Video',
                    component: StatisticVideoFilterComponent,
                    type: 'videos'
                },{
                    selectorTitle: 'Article',
                    component: StatisticArticleFilterComponent,
                    type: 'articles'
                }]
            }
        },
        methods:{
            addFilter(){
                if (this.activeFilters.length < this.availableFilters.length) {
                    this.activeFilters.length
                        ? this.activeFilters.push(_.without(this.availableFilters, this.activeFilters[0])[0])
                        : this.activeFilters.push(this.availableFilters[0]);
                }
            },
            removeFilter(index){
                this.activeFilters.splice(index, 1);
            },
            getUnactiveItems(field){
                return _.without(this.activeFilters, field)
            },
            changeFilterType(typeObj, index){
                this.activeFilters.splice(index, 1, typeObj);
                this.$emit('clearTypeFilters');
            },
        },
        mounted() {
            if (this.$store.state.statistics.videoFiltersList.length) {
                this.activeFilters.push(this.availableFilters[0])
            }
            if (this.$store.state.statistics.articlesFiltersList.length || this.$route.query.article_id !== undefined) {
                this.activeFilters.push(this.availableFilters[1])
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter {
        justify-content: space-between;
        align-items: flex-start;
        float: none!important;
        margin: 20px 0 0 0!important;

        ::v-deep .dropdown {
            padding: 0 20px;
            border: 1px solid #afc7da;
            font-weight: bold;
            font-size: 16px;
            display: flex;
            align-items: center;
            color: #89a7c1;
            height: 54px;
            width: 140px;

            .list {
                width: 140px;
                background: #fff;
                top: 54px;
                left: -1px;
                border: 1px solid #afc7da;

                .list-item {
                    padding: 7px 20px;
                    &.active {
                        background-color: #e8e8e8;
                    }
                }
            }
        }
    }

    .add-filter-btn {
        &.btn {
            height: 48px;
            width: 140px;
        }
    }

</style>
