<template src="../../../assets/templates/search-component.html">
    <!--    Общая логика находится в миксине searchMixin.
            Темплейт общий для нескольких компонентов использующих этот миксин - ../../../assets/templates/search-component.html
            Стили тоже общие - ../../../assets/css/search-video-component.scss
    -->
</template>

<script>
    import {mapActions} from 'vuex';
    import _ from 'lodash';
    import filterByTitleMixin from '../../mixins/searchMixin.js'

    export default {
        name: "VideoFilterComponent",
        mixins:[filterByTitleMixin],
        data(){
            return {
                resultList: this.$store.state.statistics.videoFiltersList,
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            ...mapActions(['SEARCH_VIDEO_TO_STATISTIC_FILTER']),
            doSearch: _.debounce(function (value) { // Запрос отправляется не чаще чем раз в пол секунды
                this.SEARCH_VIDEO_TO_STATISTIC_FILTER({
                    query: value,
                    userType: this.$store.state.user.user_type
                }).then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                })
            }, 500),
            clearHints(){
                this.searchQuery = '';
                this.$store.commit('SET_VIDEO_HINTS_TO_STATISTICS_FILTER', []);
            },
        },
        computed:{
            hints(){
                return this.$store.state.statistics.videoHintList
            },
        },
    }
</script>

<style src="../../../assets/css/search-video-component.scss" lang="scss" scoped >

</style>
