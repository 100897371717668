<template>
    <div class="video-filter-wrapper flex-between">
        <div class="video-filter flex">
            <label class="search-video">
                <input type="text"
                       placeholder="Поиск по библиотеке видео"
                       v-model.trim="searchQuery"
                >
            </label>
            <img class="search-icon" :src="`${staticDomain}/img/search.png`" alt="">
        </div>
    </div>

</template>

<script>
    import _ from 'lodash';

    export default {
        name: "VideoFilterComponent",
        data(){
            return {
                searchQuery: this.$route.params.initSearchQuery || '',
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
        methods: {
            doSearch: _.debounce(function (value) { // Запрос отправляется не чаще чем раз в пол секунды
                if (value.length > 1) {
                    this.$emit('search', this.searchQuery)
                } else {
                    this.$emit('search', '')
                }
            }, 500),
        },
        watch:{
            searchQuery(val){
                this.doSearch(val);
            }
        },
    }
</script>

<style src="../../../assets/css/search-video-component.scss" lang="scss" scoped ></style>
<style lang="scss" scoped>
    .video-filter-wrapper {
        flex: 1;
        padding: 0 20px 0 0 ;
        @media (max-width: 900px) {
            padding: 0 15px 0 0 ;
        }
        @media (max-width: 600px) {
            padding: 0;
        }
    }
    .search-video {
        width: 100%!important;
        padding-right: 35px;
        @media (max-width: 600px) {
            padding-right: 26px;
        }
    }
    .video-filter, input {
        background-color: #e8e8e8;
        height: 56px;
        @media (max-width: 900px) {
            height: 50px;
        }
    }
    .video-filter {
        padding: 0 10px!important;
        @media (max-width: 600px) {
            img {
                top: 16px!important;
                right: 10px!important;
            }
        }
    }
</style>
