<template>
    <div class="statistics-wrapper">
        <div class="flex-between filters-wrapper">
            <DateFilterComponent class="static-filter" @changeDateInterval="setDateInterval"></DateFilterComponent>
            <FilterTypeSelectorComponent class="static-filter" @setFilterType="changeFilterGroup"></FilterTypeSelectorComponent>
        </div>
        <VideoFilterComponent @change="changeVideoFilters"></VideoFilterComponent>
        <StatisticsTableComponent
                :statisticData="statisticData"
                :loading="loading"
                @doSort="doSort"
                v-scroll="handleScroll"
        ></StatisticsTableComponent>
    </div>
</template>

<script>
    import DateFilterComponent from "./DateFilterComponent";
    import FilterTypeSelectorComponent from "./FilterTypeSelectorComponent";
    import StatisticsTableComponent from "./StatisticsTableComponent";
    import VideoFilterComponent from "./StatisticVideoFilterComponent";
    import {mapCacheActions } from 'vuex-cache';
    import _ from 'lodash';

    export default {
        name: "StatisticsComponent",
        data(){
            return {
                // filters:{
                //     start_date: null,
                //     end_date: null,
                //     videos: [],
                //     group: this.$store.state.statistics.currentStatisticGroup,
                // },
                filters: this.$store.state.statistics.filters,
                sortBy: this.$store.state.statistics.sortBy,
                videoFiltersList: this.$store.state.statistics.videoFiltersList,
                loading: false,
                currentPage: 0,
                totalCount: 0,
                cacheTimeout: 1000 * 60 * 2
            }
        },
        methods: {
            ...mapCacheActions (['GET_STATISTIC','GET_NEXT_STATISTIC_PAGE']),
            createQueryStringToRequest(){
                let queryString = `?group=${this.filters.group}&sort_field=${this.filters.group}&limit=${this.filters.limit}&page=${this.currentPage}`;

                this.filters.videos.forEach((video)=>{
                    queryString+=`&videos[]=${video.id}`
                });
                this.filters.start_date ? queryString+=`&start_date=${this.getDateIntervalString(this.filters.start_date)}` : null;
                this.filters.end_date ? queryString+=`&end_date=${this.getDateIntervalString(this.filters.end_date)}` : null;
                queryString+=`&sort_direction=${this.filters.sortingState[this.filters.group]}`;

                return queryString
            },
            changeFilterGroup(type){
                this.filters.group = type;
            },
            changeVideoFilters(videos){
                this.filters.videos = videos;
            },
            handleChangingFilters(){
                this.currentPage = 0; // При смене фильтра обязательно обнуляем номер страницы для запроса
                this.fetchStatisticData();
            },
            fetchStatisticData(){
                this.GET_STATISTIC({
                    query: this.createQueryStringToRequest(),
                    userType: this.$store.state.user.user_type
                },{
                    timeout: this.cacheTimeout
                })
                .then((response)=>{
                    this.$store.commit('SET_STATISTIC', response);
                    this.loading = false;
                    this.totalCount = response.count;
                })
            },
            loadAndAppendStatisticData(){
                this.GET_NEXT_STATISTIC_PAGE({
                    query: this.createQueryStringToRequest(),
                },{
                    timeout: this.cacheTimeout
                })
                .then((response)=>{
                    this.$store.commit('APPEND_STATISTIC', response);
                    this.loading = false;
                })
                .catch(()=>{
                    this.loading = false;
                })
            },
            setDateInterval(interval){
                Object.assign(this.filters, {
                    start_date: interval[0],
                    end_date: interval[1],
                })
            },
            getDateIntervalString(interval){ // Поправка Date.toISOString на часовой пояс
                let timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
                return (new Date(interval - timezoneOffset)).toISOString().slice(0, 10); //YYYY-MM-DD
            },
            doSort(by){
                this.filters.sortingState[by] = this.filters.sortingState[by] ==='asc' ? 'desc' : 'asc';
            },
            handleScroll:  _.throttle(function(e, el) {
                if (this.statisticData.length >= this.totalCount || this.loading) return; //Если все страницы загружены или в данный момент идет загрузка следующей страницы, то игнорируем обработку скрола

                let pos = el.getBoundingClientRect();
                if(pos.top + pos.height > window.innerHeight) return;
                this.loading = true;
                this.currentPage++;
                this.loadAndAppendStatisticData();
            }, 200, {
                leading:true
            }),
            handleInitRouteParams(){
                if (this.$route.query.video_id !== undefined){
                    this.$store.commit('SET_STATISTIC_VIDEO_FILTERS_LIST', [{
                        id: this.$route.query.video_id,
                        title: this.$route.query.title || 'video',
                    }]);
                    this.$store.commit('SET_STATISTIC_FILTERS', {
                        videos: [{id: this.$route.query.video_id}],
                        group: 'video',
                    });
                    // this.filters.videos.splice(0, this.filters.videos.length, {id: this.$route.query.video_id})
                }
            },
        },
        watch: {
            filters: {
                handler(){
                    this.handleChangingFilters();
                },
                deep: true
            },
        },
        created(){
            this.handleInitRouteParams();
            this.handleChangingFilters();
        },
        computed:{
            statisticData(){
                return this.$store.state.statistics.statisticData
            },
        },
        components: {
            DateFilterComponent,
            FilterTypeSelectorComponent,
            StatisticsTableComponent,
            VideoFilterComponent,
        }
    }
</script>

<style lang="scss" scoped>
    .statistics-wrapper {
        flex: 1;
        width: 100%;
    }
    .filters-wrapper {
        @media(max-width: 768px){
            flex-wrap: wrap;
        }
    }
    .static-filter {
        width: 50%;
        padding-top: 28px;
        padding-bottom: 28px;
        @media(max-width: 900px){
            padding-top: 10px;
            padding-bottom: 10px;
        }
        @media(max-width: 768px){
            width: 100%;
        }
    }

</style>
